const Permissions = {
  OPERATOR: 'OPERATOR',

  DATA_CATEGORIES_VIEWER: 'DATA_CATEGORIES_VIEWER',
  DATA_CATEGORIES_EDITOR: 'DATA_CATEGORIES_EDITOR',
  DATA_CATEGORIES_ADMIN: 'DATA_CATEGORIES_ADMIN',

  SYSTEMS_VIEWER: 'SYSTEMS_VIEWER',
  SYSTEMS_EDITOR: 'SYSTEMS_EDITOR',
  SYSTEMS_ADMIN: 'SYSTEMS_ADMIN',

  PROCESSINGS_VIEWER: 'PROCESSINGS_VIEWER',
  PROCESSINGS_EDITOR: 'PROCESSINGS_EDITOR',
  PROCESSINGS_ADMIN: 'PROCESSINGS_ADMIN',

  TASKS_VIEWER: 'TASKS_VIEWER',
  TASKS_EDITOR: 'TASKS_EDITOR',
  TASKS_ADMIN: 'TASKS_ADMIN',

  DATA_ACTORS_VIEWER: 'DATA_ACTORS_VIEWER',
  DATA_ACTORS_EDITOR: 'DATA_ACTORS_EDITOR',
  DATA_ACTORS_ADMIN: 'DATA_ACTORS_ADMIN',

  TASK_GRANT_VIEWER: 'TASK_GRANT_VIEWER',
  TASK_GRANTS_EDITOR: 'TASK_GRANTS_EDITOR',
  TASK_GRANTS_ADMIN: 'TASK_GRANTS_ADMIN',

  DATA_SUBJECTS_VIEWER: 'DATA_SUBJECTS_VIEWER',
  DATA_SUBJECTS_EDITOR: 'DATA_SUBJECTS_EDITOR',
  DATA_SUBJECTS_ADMIN: 'DATA_SUBJECTS_ADMIN',

  CONSENTS_VIEWER: 'CONSENTS_VIEWER',
  CONSENTS_EDITOR: 'CONSENTS_EDITOR',
  CONSENTS_ADMIN: 'CONSENTS_ADMIN',

  AUDITS_VIEWER: 'AUDITS_VIEWER',
  AUDITS_EDITOR: 'AUDITS_EDITOR',
  AUDITS_ADMIN: 'AUDITS_ADMIN',

  CONTRACTS_VIEWER: 'CONTRACTS_VIEWER',
  CONTRACTS_EDITOR: 'CONTRACTS_EDITOR',
  CONTRACTS_ADMIN: 'CONTRACTS_ADMIN',

  USERS_VIEWER: 'USERS_VIEWER',
  USERS_EDITOR: 'USERS_EDITOR',
  USERS_ADMIN: 'USERS_ADMIN',

  STEWARDSHIP_VIEWER: 'STEWARDSHIP_VIEWER',
  STEWARDSHIP_EDITOR: 'STEWARDSHIP_EDITOR',
  STEWARDSHIP_ADMIN: 'STEWARDSHIP_ADMIN',

  CLASSIFICATION_VIEWER: 'CLASSIFICATION_VIEWER',
  CLASSIFICATION_EDITOR: 'CLASSIFICATION_EDITOR',
  CLASSIFICATION_ADMIN: 'CLASSIFICATION_ADMIN',

  CAMPAIGNS_VIEWER: 'CAMPAIGNS_VIEWER',
  CAMPAIGNS_EDITOR: 'CAMPAIGNS_EDITOR',
  CAMPAIGNS_ADMIN: 'CAMPAIGNS_ADMIN',

  QUALITY_VIEWER: 'QUALITY_VIEWER',
  QUALITY_EDITOR: 'QUALITY_EDITOR',
  QUALITY_ADMIN: 'QUALITY_ADMIN',

  PROBES_VIEWER: 'PROBES_VIEWER',
  PROBES_EDITOR: 'PROBES_EDITOR',
  PROBES_ADMIN: 'PROBES_ADMIN',

  QUALITY_ASSMNT_VIEWER: 'QUALITY_ASSMNT_VIEWER',
  QUALITY_ASSMNT_EDITOR: 'QUALITY_ASSMNT_EDITOR',
  QUALITY_ASSMNT_ADMIN: 'QUALITY_ASSMNT_ADMIN',

  QUERY_PARSER_VIEWER: 'QUERY_PARSER_VIEWER',
  QUERY_PARSER_EDITOR: 'QUERY_PARSER_EDITOR',
  QUERY_PARSER_ADMIN: 'QUERY_PARSER_ADMIN',

  AGENTS_VIEWER: 'AGENTS_VIEWER',
  AGENTS_EDITOR: 'AGENTS_EDITOR',
  AGENTS_ADMIN: 'AGENTS_ADMIN',

  PROPOSE: 'PROPOSE',
  APPROVE: 'APPROVE',

  DATA_PRODUCTS_VIEWER: 'DATA_PRODUCTS_VIEWER',
  DATA_PRODUCTS_EDITOR: 'DATA_PRODUCTS_EDITOR',
  DATA_PRODUCTS_ADMIN: 'DATA_PRODUCTS_ADMIN',

  GOVERNANCE_POLICIES_VIEWER: 'GOVERNANCE_POLICIES_VIEWER',
  GOVERNANCE_POLICIES_EDITOR: 'GOVERNANCE_POLICIES_EDITOR',
  GOVERNANCE_POLICIES_ADMIN: 'GOVERNANCE_POLICIES_ADMIN',

  DATAOPS_VIEWER: 'DATAOPS_VIEWER',
  DATAOPS_EDITOR: 'DATAOPS_EDITOR',
  DATAOPS_ADMIN: 'DATAOPS_ADMIN',

  BLUEPRINTS_VIEWER: 'BLUEPRINTS_VIEWER',
  BLUEPRINTS_EDITOR: 'BLUEPRINTS_EDITOR',
  BLUEPRINTS_ADMIN: 'BLUEPRINTS_ADMIN',

  PROFILING_VIEWER: 'PROFILING_VIEWER',
  PROFILING_EDITOR: 'PROFILING_EDITOR',
  PROFILING_ADMIN: 'PROFILING_ADMIN'
};

export const permissionsEntities = {
  DATA_CATEGORIES: 'DATA_CATEGORIES',
  PROCESSINGS: 'PROCESSINGS',
  TASKS: 'TASKS',
  DATA_ACTORS: 'DATA_ACTORS',
  SYSTEMS: 'SYSTEMS',
  //TASK_GRANT: 'TASK_GRANT',
  //TASK_GRANTS: 'TASK_GRANTS',
  DATA_SUBJECTS: 'DATA_SUBJECTS',
  CONSENTS: 'CONSENTS',
  //AUDITS: 'AUDITS',
  CONTRACTS: 'CONTRACTS',
  USERS: 'USERS',
  STEWARDSHIP: 'STEWARDSHIP',
  CLASSIFICATION: 'CLASSIFICATION',
  CAMPAIGNS: 'CAMPAIGNS',
  QUALITY: 'QUALITY',
  AGENTS: 'AGENTS',
  PROBES: 'PROBES',
  QUALITY_ASSMNT: 'QUALITY_ASSMNT',
  QUERY_PARSER: 'QUERY_PARSER',
  DATA_PRODUCTS: 'DATA_PRODUCTS',
  PROFILING: 'PROFILING',
  GOVERNANCE_POLICIES: 'GOVERNANCE_POLICIES',
  DATAOPS: 'DATAOPS',
  BLUEPRINTS: 'BLUEPRINTS'
};

export const PermissionsRoles = {
  VIEWER: 'VIEWER',
  EDITOR: 'EDITOR',
  ADMIN: 'ADMIN'
};

export default Permissions;
