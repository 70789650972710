import { combineReducers } from 'redux';
import authentication from './authentication';
import behaviour from './behaviour';
import datacategories from './datacategories';
import logicalfields from './logicalfields';
import systems from './systems';
import dataflows from './dataflows';
import physicalentities from './physicalentities';
import systemlogins from './systemlogins';
import data_actors from './data_actors';
import tasks from './tasks';
import taskAssignments from './taskAssignments';
import processings from './processings';
import taskgrants from './taskgrants';
import graph from './graph';
import settings from './settings';
import consents from './consents';
import notary from './notary';
import contracts from './contracts';
import dashboards from './dashboards';
import reports from './reports';
import history from './history';
import workflows from './workflows';
import physicalFields from './physicalFields';
import account from './account';
import templates from './templates';
import campaigns from './issueMngt';
import social from './social';
import classification from './classification';
import quality from './quality';
import stewardship from './stewardship';
import engagement from './engagement';
import logicalrelations from './logicalrelations';
import systemRoutines from './systemRoutines';
import physicalconstraints from './physicalconstraints';
import dataproducts from './dataproducts';
import dataops from './dataops';
import logical from './logical';
import { LOGOUT, SELECT_TENANT } from '../constants/ActionTypes';

const appReducer = combineReducers({
  authentication,
  behaviour,
  datacategories,
  logicalfields,
  systems,
  dataproducts,
  dataops,
  physicalentities,
  physicalconstraints,
  systemRoutines,
  systemlogins,
  dataflows,
  data_actors,
  processings,
  tasks,
  taskAssignments,
  templates,
  workflows,
  consents,
  notary,
  taskgrants,
  graph,
  settings,
  contracts,
  dashboards,
  reports,
  history,
  physicalFields,
  campaigns,
  account,
  social,
  classification,
  quality,
  stewardship,
  engagement,
  logicalrelations,
  logical
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case LOGOUT:
      return appReducer(undefined, action);
    case SELECT_TENANT:
      return appReducer(
        { authentication: state.authentication, settings: { profile: state.settings.profile } },
        action
      );
    default:
      return appReducer(state, action);
  }
};
